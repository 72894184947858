import React from 'react';

const PolicyPage = () => {
	const pdfViewStyle = {
		height: '100vh',
		width: '100%',
	};

	return (
		<div>
			<iframe
				src="/policy.pdf"
				title="ÁSZF"
				width="100%"
				style={pdfViewStyle}
			/>
		</div>
	);
};

export default PolicyPage;
